import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../src/Context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();
  // console.log('User Auth:', user);

  if (!user) {
    // console.log('User not authenticated. Redirecting to /login');
    return <Navigate to="/" />;
  }

  // console.log('User authenticated. Rendering children.');
  return children;
};

export default ProtectedRoute;
