import React from 'react'
import NavBar from '../Navbar/NavBar'
import HeroAbout from '../About/HeroAbout'

const AboutPage = () => {
  return (
    <div>
      <div>
        <NavBar/>
      </div>
      <div>
        <HeroAbout/>
      </div>
    </div>
  )
}

export default AboutPage