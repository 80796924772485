import React from "react";
import NavBar from "../Navbar/NavBar";
import HeroInfoScreen from "../Home/HeroInfoScreen";
import SectionClasses from "../Home/SectionClasses";

const Home = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        <HeroInfoScreen />
      </div>
      <div>
        <SectionClasses/>
      </div>
    </div>
  );
};

export default Home;
