// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getAuth } from 'firebase/auth';
import "firebase/compat/firestore";
import "firebase/compat/storage"; 

const firebaseConfig = {
    apiKey: "AIzaSyBif1d9uTjbs0eaC9Ritr-6zNYMacok5s4",
    authDomain: "classmanagementapp-a0d12.firebaseapp.com",
    projectId: "classmanagementapp-a0d12",
    storageBucket: "classmanagementapp-a0d12.appspot.com",
    messagingSenderId: "700667287984",
    appId: "1:700667287984:web:956dc44fd6f61e7e109e75",
    measurementId: "G-4HWCWTWMKD"
  };


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth();
const db = app.firestore();
const storage = firebase.storage(); // Use firebase.storage() to get the storage object

export { app, auth, db, storage };