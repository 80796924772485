import { createContext, useContext, useEffect, useState } from "react";
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithRedirect,
  sendEmailVerification,
} from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { setDoc, doc } from "@firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Add isAuthenticated state

  const createUser = async (email, password) => {
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Access the user from the userCredential
      const user = userCredential.user;

      // Set Firestore data for the user
      await setDoc(doc(db, 'users', email), {
        savedShows: []
      });

      return user; // Optionally, you can return the user if needed
    } catch (error) {
      console.error("Error creating user:", error.message);
      throw error; // Re-throw the error to handle it in the component that calls this function
    }
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const sendVerificationEmail = (userToVerify) => {
    return sendEmailVerification(userToVerify);
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser || null);  // Set user to null if undefined
      setIsAuthenticated(!!currentUser); // Update isAuthenticated based on currentUser
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn, googleSignIn, sendVerificationEmail, isAuthenticated }}> {/* Add isAuthenticated to the context value */}
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
