import React from "react";
import { AuthContextProvider } from "./Context/AuthContext";
import ErrorBoundary from "./ErrorBoundary";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./components/pages/HomePage";
import ProtectedRoute from "./protectedRoute";
import Login from "./components/User/Login/Login";
import SignUp from "./components/User/SignUp/SignUp";
import AboutPage from "./components/pages/AboutPage";
import ContactPage from "./components/pages/ContactPage";
import AcademyPage from "./components/pages/AcademyPage";
import RefereeAll from "./components/ExamCenter/RefereeExam/RefereeAll";

function App() {
  return (
    <AuthContextProvider>
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            {/* If user is not authenticated, redirect to login page */}
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route
              path="/academy"
              element={
                <ProtectedRoute>
                  <AcademyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/exam"
              element={
                <ProtectedRoute>
                  <RefereeAll />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </ErrorBoundary>
    </AuthContextProvider>
  );
}

export default App;
