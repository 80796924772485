import React, { useState, useEffect } from "react";
import { db, auth } from "../../../firebaseConfig";
import questions from "./QuestionSeries";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import VideoComponent from "./VideoComponent"; // Import the VideoComponent

const RefereeAll = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [refereeData, setRefereeData] = useState([]);
  const [refereeId, setRefereeId] = useState("");
  const [userUid, setUserUid] = useState(""); // State to store user UID
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [examStarted, setExamStarted] = useState(false);
  const [startTime, setStartTime] = useState(null); // Variable to store start time
  const [timeElapsed, setTimeElapsed] = useState(0); // Variable to store elapsed time
  const [timerInterval, setTimerInterval] = useState(null); // Interval reference
  const [examResult, setExamResult] = useState("");
  const [showScoreSheet, setShowScoreSheet] = useState(false);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  // New state variables
  const [selectedReferee, setSelectedReferee] = useState(""); // Selected referee name
  const [pdfData, setPdfData] = useState(""); // PDF data from Firestore
  const examDuration = 30 * 60; // 30 minutes in seconds

  useEffect(() => {
    let timerInterval;

    if (examStarted) {
      setStartTime(Date.now());

      timerInterval = setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        setTimeElapsed(elapsedTime);

        if (elapsedTime >= examDuration) {
          endExam();
        }
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [examStarted, startTime, examDuration, timerInterval]);

  // Function to start the exam
  const startExam = () => {
    setExamStarted(true);
    shuffleQuestions();
  };

  const shuffleQuestions = () => {
    // Shuffle questions array
    const shuffledQuestions = questions.sort(() => Math.random() - 0.5);
    // Select first 30 questions
    const selectedQuestions = shuffledQuestions.slice(0, 30);
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
  };

  const handleAnswer = (answer) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestionIndex] = answer;
    setUserAnswers(updatedAnswers);

    // Move to the next question
    if (currentQuestionIndex < 29) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      // Calculate score and display result
      const correctAnswers = userAnswers.reduce((count, answer, index) => {
        const currentQuestion = questions[index];
        return answer === currentQuestion.answer ? count + 1 : count;
      }, 0);

      setCorrectAnswersCount(correctAnswers);

      if (correctAnswers >= 14) {
        setExamResult("Congratulations! You passed the exam.");
      } else {
        setExamResult("Sorry, you failed the exam.");
      }

      setShowScoreSheet(true);
    }
  };

  useEffect(() => {
    const fetchUserUid = async () => {
      try {
        const user = auth.currentUser; // Get the current user
        if (user) {
          setUserUid(user.uid); // Set the user UID in state
        }
      } catch (error) {
        console.error("Error fetching user UID:", error.message);
      }
    };
    fetchUserUid();
  }, []);

  useEffect(() => {
    if (userUid) {
      const fetchClasses = async () => {
        try {
          const snapshot = await db
            .collection(`users/${userUid}/classes`)
            .get();
          const classNames = snapshot.docs.map((doc) => doc.id);
          setClasses(classNames);
          console.log("Fetched classes:", classNames);
        } catch (error) {
          console.error("Error fetching classes:", error.message);
        }
      };
      fetchClasses();
    }
  }, [userUid]);

  const handleRefereeSearch = async () => {
    try {
      if (!selectedClass || !refereeId) return;

      const snapshot = await db
        .collection(`users/${userUid}/classes/${selectedClass}/referees`)
        .where("refereeId", "==", refereeId)
        .get();

      const data = snapshot.docs.map((doc) => doc.data());
      setRefereeData(data);
      console.log("Fetched referee data:", data);
    } catch (error) {
      console.error("Error fetching referee data:", error.message);
    }
  };

  const endExam = () => {
    setExamStarted(false);
    clearInterval(timerInterval); // Stop the timer
    setTimeElapsed(0); // Reset elapsed time

    // Calculate the exam result based on the submitted answers
    const correctAnswers = userAnswers.reduce((count, answer, index) => {
      const currentQuestion = questions[index];
      return answer === currentQuestion.answer ? count + 1 : count;
    }, 0);

    setCorrectAnswersCount(correctAnswers);

    if (correctAnswers >= 14) {
      setExamResult("passed");
    } else {
      setExamResult("failed");
    }

    setShowScoreSheet(true); // Show the score sheet
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const generatePDF = async () => {
    for (const [index, referee] of refereeData.entries()) {
      const doc = new jsPDF();
      const currentTime = new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const currentDate = new Date().toLocaleDateString("en-US");
      const horizontalSpace = 100;

      // Load the karate logo image
      const karateLogoImg = await fetch(
        "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/World_Karate_Federation_logo.svg/316px-World_Karate_Federation_logo.svg.png"
      );
      const karateLogoBlob = await karateLogoImg.blob();
      const karateLogoURL = URL.createObjectURL(karateLogoBlob);

      // Add the karate logo image to the PDF
      doc.addImage(karateLogoURL, "PNG", 10, 3, 20, 20); // Adjust the position and size as per your requirement

      doc.setFontSize(16);
      doc.text(`Referee Exam Result`, 105, 10, { align: "center" });
      doc.text(
        `Skylight Academy Of Education and Sport Services, Pune`,
        105,
        20,
        { align: "center" }
      );

      const yPos = 30;
      doc.setFontSize(10);
      doc.text(`Referee Details:`, 10, yPos);
      doc.text(`Full Name: ${referee.fullName}`, 10, yPos + 10);
      doc.text(`Address: ${referee.address}`, 10 + horizontalSpace, yPos + 10);
      doc.text(`City: ${referee.city}`, 10, yPos + 20);
      doc.text(`State: ${referee.state}`, 10 + horizontalSpace, yPos + 20);
      doc.text(`Qualification: ${referee.qualification}`, 10, yPos + 30);
      doc.text(
        `Sport Qualification: ${referee.sportQualification}`,
        10 + horizontalSpace,
        yPos + 30
      );
      doc.text(`Date of Birth: ${referee.dob}`, 10, yPos + 40);
      doc.text(`Age: ${referee.age}`, 10 + horizontalSpace, yPos + 40);
      doc.text(`Sport Category: ${referee.sportCategory}`, 10, yPos + 50);
      doc.text(
        `Referee ID: ${referee.refereeId}`,
        10 + horizontalSpace,
        yPos + 50
      );
      doc.text(`Exam Date: ${referee.examDate}`, 10, yPos + 60);
      doc.text(`Exam Result: ${examResult}`, 10 + horizontalSpace, yPos + 60);
      doc.text(`Score: ${correctAnswersCount} out of 30`, 10, yPos + 70);
      doc.text(`Class Name: ${selectedClass}`, 10, yPos + 80);
      doc.text(`Date: ${currentDate}`, 10, yPos + 85);
      doc.text(`Time: ${currentTime}`, 10, yPos + 90);
      doc.rect(5, yPos - 5, 200, 100); // Border around the details

      // Load the referee image from the database
      const refereeImageURL = referee.imageUrl; // Assuming you have the image URL stored in referee.imageUrl
      console.log("Referee Image URL:", refereeImageURL);
      // Add the referee image to the PDF
      const imgWidth = 50; // Adjust the width of the image as per your requirement
      const imgHeight = 50; // Adjust the height of the image as per your requirement
      doc.addImage(
        refereeImageURL,
        "JPEG",
        10 + horizontalSpace,
        80,
        imgWidth,
        imgHeight
      );

      // Description, Notes, and Declaration
      const descriptionYPos = yPos + 100;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text(`Description:`, 10, descriptionYPos);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text(
        `Skylight Academy endeavors to provide comprehensive training and certification programs for individuals aspiring to  become referees for various sports events\norganized by the academy. The referee certification program adheres to the  rules and guidelines established by the World Karate Federation (WKF),\nan internationally recognized authorityin karate and related disciplines.`,
        10,
        descriptionYPos + 10
      );

      const notesYPos = descriptionYPos + 30;
      doc.setFont("helvetica", "bold");
      doc.text(`Notes:`, 10, notesYPos);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text(
        `1. Compliance with WKF Guidelines: Referees certified by Skylight Academy are expected to adhere strictly to the rules and regulations stipulated\nby the WKF during the officiation of karate events.\n2. Scope of Authority: The referee certification issued by Skylight Academy is valid solely for officiating academy-sponsored sports events.\nReferees are authorized to oversee competitions and enforce rules only within the context of Skylight Academy's organized sporting activities.\n3. Continued Education: Referees are encouraged to stay abreast of updates and developments in WKF regulations through continuous education and training\nprograms offered by Skylight Academy.`,
        10,
        notesYPos + 10
      );

      const declarationYPos = notesYPos + 50;
      doc.setFont("helvetica", "bold");
      doc.text(`Declaration:`, 10, declarationYPos);
      doc.text(
        `I,[${referee.fullName}], hereby acknowledge and agree to the following
        \n- I understand that the referee certification provided by Skylight Academy is valid exclusively for officiating academy-sponsored sports events.\n- I commit to upholding the standards and principles outlined by the World Karate Federation (WKF) while officiating karate competitions organized\nby Skylight Academy.\n- I undertake to undergo further training and education as necessary to enhance my knowledge and understanding of WKF rules and regulation\n- I acknowledge that any misuse or misrepresentation of the referee certification may result in the revocation of my accreditation\nby Skylight Academy.
  
      By affixing my signature below, I affirm my understanding and acceptance of the terms and conditions outlined above.
  
      [Signature] _________________________  
      [Date] _________________________`,
        10,
        declarationYPos + 10
      );

      // Save the PDF with the referee's name
      const pdfFileName = `referee_${referee.fullName}_exam_result.pdf`;

      // Trigger the download of the PDF file
      doc.save(pdfFileName);
      console.log("PDF generated for referee:", referee.fullName);

      // Encode the PDF data to base64
      const pdfData = doc.output("datauristring"); // Data URL string
      const base64Data = pdfData.split(",")[1]; // Get the base64 data part

      // Save the base64 encoded PDF data to Firestore
      try {
        await db
          .collection(`users/${userUid}/classes/${selectedClass}/referees`)
          .doc(referee.fullName)
          .collection("examResults")
          .add({
            pdfData: base64Data,
            examResult: examResult,
            score: correctAnswersCount,
            timestamp: new Date(),
          });
        console.log(
          "PDF data saved to Firestore for referee:",
          referee.fullName
        );
      } catch (error) {
        console.error("Error saving PDF data to Firestore:", error.message);
      }
    }
  };

  // Function to handle the view PDF button click
  const handleViewPdf = async () => {
    try {
      if (!selectedReferee) return;

      console.log("Fetching PDF data for referee:", selectedReferee);

      const refereeRef = db
        .collection(`users/${userUid}/classes/${selectedClass}/referees`)
        .doc(selectedReferee)
        .collection("examResults");

      const querySnapshot = await refereeRef.get();

      querySnapshot.forEach((doc) => {
        const pdfData = doc.data().pdfData;
        console.log("PDF data retrieved successfully:", pdfData);
        // Open the PDF in a new window or tab
        const newWindow = window.open();
        newWindow.document.write(
          '<iframe width="100%" height="100%" src="data:application/pdf;base64,' +
            encodeURI(pdfData) +
            '"></iframe>'
        );
      });
    } catch (error) {
      console.error("Error fetching PDF data:", error.message);
    }
  };

  // Function to display PDF content or trigger download
  const displayPdf = () => {
    // Display or download the PDF based on your requirements
    if (pdfData) {
      // Here you can display the PDF content or provide a download link
      // For simplicity, let's trigger the download
      console.log("Downloading PDF for referee:", selectedReferee);
      const pdfBlob = b64toBlob(pdfData, "application/pdf");
      saveAs(pdfBlob, `referee_${selectedReferee}_exam_result.pdf`);
    } else {
      console.log("PDF data is not available for download.");
    }
  };

  // Helper function to convert base64 to Blob
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  return (
    <div className="container mx-auto md:px-4 md:py-8 w-full">
      <div>
        <div className="md:grid grid-cols-1">
          <div className="md:flex md:justify-start md:items-center md:gap-8 p-5 font-semibold col-span-2">
            <label htmlFor="class">Select Class:</label>
            <select
              id="class"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Class</option>
              {classes.map((className) => (
                <option key={className} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>
          <div className="md:flex justify-start items-center gap-4 p-5 font-semibold">
            <label htmlFor="refereeId">Enter Referee ID:</label>
            <input
              type="text"
              id="refereeId"
              value={refereeId}
              onChange={(e) => setRefereeId(e.target.value)}
              className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleRefereeSearch}
              className="bg-blue-500 text-white px-4 py-2 rounded-md ml-2 focus:outline-none"
            >
              Search
            </button>
          </div>
          <div className="md:col-span-1">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/World_Karate_Federation_logo.svg/316px-World_Karate_Federation_logo.svg.png"
              alt="karate_logo"
              className="object-cover w-32 h-32"
            />
          </div>
        </div>
      </div>

      <div className="mt-8 mx-auto p-4 md:p-2">
        {refereeData.map((referee, index) => (
          <div
            key={index}
            className="border border-blue-500 rounded-md p-4 flex"
          >
            <div className="md:flex md:flex-col mr-4 w-full text-gray-500 text-md font-semibold">
              <div>
                <div className="grid md:grid-cols-2 md:gap-8 mb-4">
                  <h1>Full Name: {referee.fullName}</h1>
                  <h5>Address: {referee.address}</h5>
                  <h5>City: {referee.city}</h5>
                  <h5>State: {referee.state}</h5>
                </div>
                <hr />
                <div className="md:grid md:grid-cols-2 md:gap-8 mt-4 mb-4">
                  <h5>Qualification: {referee.qualification}</h5>
                  <h5>Sport Qualification: {referee.sportQualification}</h5>
                  <h5>Date of Birth: {referee.dob}</h5>
                  <h5>Age: {referee.age}</h5>
                  <h5>Sport Category: {referee.sportCategory}</h5>
                </div>
              </div>
              <hr />
              <div className="md:grid md:grid-cols-2 md:gap-8 mt-4">
                <h5>Referee ID: {referee.refereeId}</h5>
                <h5>Exam Date: {referee.examDate}</h5>
              </div>
            </div>
            <div>
              <img
                src={referee.imageUrl}
                alt="Referee Identity"
                className="object-cover w-32 h-32 rounded-lg"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="container mx-auto px-4 py-8 flex flex-col gap-6">
        <h2>Referee Exam</h2>
        {!examStarted && (
          <button
            onClick={startExam}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
          >
            Start Exam
          </button>
        )}
        {examStarted && (
          <div>
            <div>
              <VideoComponent />
            </div>
            <div className="text-xl ">
              <div className="border-2 border-gray-300 bg-white rounded-lg p-4 mx-auto flex gap-8 w-auto">
                <h2>
                  Q. {currentQuestionIndex + 1}:
                  {questions[currentQuestionIndex].question}
                </h2>
              </div>
              <div>
                <div className="mt-4">
                  <button
                    onClick={() => handleAnswer(true)}
                    className="bg-green-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-green-600 focus:outline-none"
                  >
                    True
                  </button>
                  <button
                    onClick={() => handleAnswer(false)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none"
                  >
                    False
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                <p>Time Elapsed: {formatTime(timeElapsed)}</p>
              </div>
            </div>
            {examResult && <p>{examResult}</p>}
            <button
              onClick={endExam}
              className="bg-red-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-red-600 focus:outline-none"
            >
              End Exam
            </button>
          </div>
        )}
        {showScoreSheet && (
          <div className="border border-gray-300 p-4 rounded-md flex flex-col">
            <div className="border-2 border-blue-300 rounded-lg p-4 mb-8">
              <h3 className="text-xl text-left   font-semibold">
                Your Score Sheet
              </h3>
              <h3 className="text-xl text-left font-semibold">
                Total Correct Answers: {correctAnswersCount} out of 30
              </h3>
              <h4 className="text-xl text-center font-semibold">
                Questions and Your Answers:
              </h4>
            </div>
            <ul className="flex flex-col gap-4 text-lg w-full">
              {questions.slice(0, 30).map((question, index) => (
                <li key={index}>
                  Q. {index + 1}: {question.question}
                  <br />
                  Correct Answer: {question.answer ? "True" : "False"}
                  <br />
                  Your Answer:{" "}
                  {userAnswers[index] !== null
                    ? userAnswers[index]
                      ? "True"
                      : "False"
                    : "Not answered"}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <button
        onClick={generatePDF}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
      >
        Generate Result Sheet
      </button>
      <div>
        {/* Dropdown menu to select referee */}
        <div className="md:flex justify-start items-center gap-4 p-5 font-semibold">
          <label htmlFor="refereeSelect">Select Referee:</label>
          <select
            id="refereeSelect"
            value={selectedReferee}
            onChange={(e) => setSelectedReferee(e.target.value)}
            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Referee</option>
            {refereeData.map((referee, index) => (
              <option key={index} value={referee.fullName}>
                {referee.fullName}
              </option>
            ))}
          </select>
        </div>

        {/* Button to view PDF */}
        <button
          onClick={handleViewPdf}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
        >
          View PDF
        </button>
      </div>
    </div>
  );
};

export default RefereeAll;
