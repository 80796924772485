import React from "react";
import NavBar from "../Navbar/NavBar";
import RefereeAll from "../ExamCenter/RefereeExam/RefereeAll";

const AcademyPage = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div className="grid-cols-4 md:grid-cols-2 p-8">
        <div className="col-span-1 border-1 rounded border-blue-400 shadow-lg">
          <RefereeAll/>
        </div>
      </div>
    </div>
  );
};

export default AcademyPage;
