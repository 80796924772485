import React from "react";
import NavBar from "../Navbar/NavBar";

const ContactPage = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        Contact Page
      </div>
    </div>
  );
};

export default ContactPage;
