import React, { useState, useEffect, useRef } from "react";

const VideoComponent = () => {
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null); // Define videoRef using useRef

  useEffect(() => {
    const enableVideoStream = async () => {
      try {
        const userStream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1280 },
            height: { ideal: 720 },
            frameRate: { ideal: 30, max: 60 },
          },
          audio: true,
        });
        setStream(userStream);
        if (videoRef.current) {
          videoRef.current.srcObject = userStream;
        }
      } catch (error) {
        console.error("Error accessing user media:", error);
      }
    };

    enableVideoStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]); // Removed videoRef from the dependency array

  return (
    <div className="w-auto md:w-fit border-2 border-black rounded-lg h-auto">
      <h2>Video Inset</h2>
      {stream && (
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="w-44 h-44"
        />
      )}
    </div>
  );
};

export default VideoComponent;
