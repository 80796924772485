import React from 'react';

const HeroInfoScreen = () => {
    return (
        <div className="flex flex-col md:flex-row items-center justify-center px-4 md:px-0  bg-slate-50">
            {/* Image */}
            <div className="md:w-1/2 mb-4 md:mb-0">
                <img src="https://img.freepik.com/free-photo/group-students-posing-with-notepads_23-2147666699.jpg?w=740&t=st=1708149837~exp=1708150437~hmac=44bfc2922c73fc489bc0ead57b4ef119259a7ecf9023296e2acfba91888d67d8" alt="Academy" className="w-full h-auto" />
            </div>
            <div className="md:w-1/2 md:pl-8 w-full h-full">
                <h2 className="text-2xl font-bold mb-4">Skylight Academy of Education and Sport Services </h2>
                <p className="text-lg text-gray-700 mb-4">
                    Skylight Academy of Education and Sports Services has earned its positive reputation because we go out of our way to provide exceptional service to each of our customers. We understand that your needs can change last minute, and we’re ready to adapt our services quickly to ensure success. Read on to learn more about what we can do for you.
                </p>
            </div>
        </div>
    );
};

export default HeroInfoScreen;
