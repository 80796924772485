import React, { useState } from "react";
import { db } from "../../firebaseConfig";
import { UserAuth } from "../../Context/AuthContext";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

const ClassesScreen = () => {
  const [city, setCity] = useState("");
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [userCity, setUserCity] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [applyForms, setApplyForms] = useState({});
  const { user } = UserAuth({});

  const handleSearch = async () => {
    setLoading(true);
    try {
      const classesRef = db
        .collection(`users/${user.uid}/classes`)
        .where("city", "==", city);
      const snapshot = await classesRef.get();
      console.log("Classes snapshot:", snapshot.docs);

  
      // Log the number of classes being fetched
      console.log("Number of classes fetched:", snapshot.size);
  
      if (snapshot.empty) {
        setErrorMessage(`No classes found for ${city}.`);
        setClasses([]);
        setLoading(false);
        return;
      }
  
      const classesData = [];
      snapshot.forEach((doc) => {
        const classData = doc.data();
        classesData.push({
          id: doc.id,
          ...classData,
        });
      });
  
      setClasses(classesData);
      setErrorMessage("");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching classes:", error);
      setErrorMessage("Error fetching classes. Please try again later.");
      setLoading(false);
    }
  };
  

  // Update handleApply function
  const handleApply = (classId) => {
    setApplyForms((prevForms) => ({
      ...prevForms,
      [classId]: true,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
    console.log("Name:", name);
    console.log("Contact Number:", contactNumber);
    console.log("City:", userCity);
    setName("");
    setContactNumber("");
    setUserCity("");
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-4">
        <label htmlFor="city" className="block font-medium text-lg mb-2">
          Enter City:
        </label>
        <input
          type="text"
          id="city"
          className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white px-4 py-2 rounded-md ml-2 focus:outline-none"
        >
          Search
        </button>
      </div>

      {!loading && !errorMessage && classes.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {classes.map((classItem) => (
            <div
              key={classItem.id}
              className="border border-gray-300 rounded-md p-4"
            >
              <h3 className="text-xl font-semibold mb-2">
                {classItem.className}
              </h3>
              <img
                src={classItem.logoUrl}
                alt={classItem.className}
                className="w-full h-auto mb-2"
              />
              <p className="mb-2">
                <strong>Category:</strong> {classItem.category}
              </p>
              <p className="mb-2">
                <strong>Description:</strong> {classItem.description}
              </p>
              <p className="mb-2">
                <strong>Trainer:</strong> {classItem.trainerName}
              </p>
              <p className="mb-2">
                <strong>Open:</strong> {formatTime(classItem.time.open)}
              </p>
              <p className="mb-2">
                <strong>Close:</strong> {formatTime(classItem.time.close)}
              </p>
              <p className="mb-2">
                <strong>Days:</strong> {classItem.days.join(", ")}
              </p>
              <div className="text-blue-500 font-bold mb-2">
                <h4>Exterior Images:</h4>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-2 mb-4">
                {classItem.exteriorImagesUrls.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Exterior ${index}`}
                    className="w-16 h-16 mt-2 mb-2 mr-2 transform transition-transform hover:scale-150 cursor-pointer"
                    onClick={() => {
                      setSelectedImage(url);
                      setIsDialogOpen(true);
                    }}
                  />
                ))}
              </div>
              <div className="text-blue-500 font-bold mb-2">
                <h4>Interior Images:</h4>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-2">
                {classItem.interiorImagesUrls.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Interior ${index}`}
                    className="w-16 h-16 mt-2 mb-2 mr-2 transform transition-transform hover:scale-150 cursor-pointer"
                    onClick={() => {
                      setSelectedImage(url);
                      setIsDialogOpen(true);
                    }}
                  />
                ))}
              </div>

              <button
                onClick={() => handleApply(classItem.id)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 mb-2 focus:outline-none"
              >
                Apply
              </button>
              {applyForms[classItem.id] && (
                <div className="bg-gray-100 rounded-md p-4">
                  <h4 className="text-lg font-semibold mb-2">
                    Apply for {classItem.className}
                  </h4>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Your Name"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none"
                    />
                    <input
                      type="tel"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      placeholder="Contact Number"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none"
                    />
                    <input
                      type="text"
                      value={userCity}
                      onChange={(e) => setUserCity(e.target.value)}
                      placeholder="Your City"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none"
                    />
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none"
                    >
                      Send
                    </button>
                  </form>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <Dialog isOpen={isDialogOpen} onDismiss={() => setIsDialogOpen(false)}>
        <button className="close-button" onClick={() => setIsDialogOpen(false)}>
          Close
        </button>
        <img src={selectedImage} alt="Selected" className="w-full h-auto" />
      </Dialog>
    </div>
  );
};

function formatTime(timeStr) {
  const [hours, minutes] = timeStr.split(":");
  let formattedHours = parseInt(hours, 10);
  const ampm = formattedHours >= 12 ? "PM" : "AM";
  formattedHours = formattedHours % 12;
  formattedHours = formattedHours ? formattedHours : 12; // If 0, convert to 12
  const formattedMinutes = minutes.padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export default ClassesScreen;
