const questions = [
  {
    id: 1,
    question:
      "If the competition area is elevated another meter to a total of 3 meters is required on all sides.",
    answer: true,
  },
  {
    id: 2,
    question: "Any Voluntary religious headwear can be worn.",
    answer: false,
  },
  {
    id: 3,
    question:
      "The karate-Gi jacket must be more than three-quarters thigh length.",
    answer: false,
  },
  {
    id: 4,
    question:
      "The karate-Gi trousers must cover at least two-thirds of the shin.",
    answer: true,
  },
  {
    id: 5,
    question:
      "The karate-Gi jacket sleeve must be no longer than the bend of the wrist.",
    answer: true,
  },
  {
    id: 6,
    question:
      "If a contestant's karate-Gi jacket sleeves are too long and a suitable replacement cannot be found in time the Referee can allow him to turn them up on the inside.",
    answer: false,
  },
  {
    id: 7,
    question:
      "Competitors may wear 1 or 2 discreet rubber band or ponytail retainer in their hair. Ribbons, beads, and other decorations are prohibited.",
    answer: false,
  },
  {
    id: 8,
    question: "Earrings are allowed if they are covered with tape.",
    answer: false,
  },
  {
    id: 9,
    question:
      "Metallic teeth (dental) braces may be worn at the contestant's own risk if approved by the Referee and the Official Doctor.",
    answer: true,
  },
  {
    id: 10,
    question:
      "Contestants must bow properly to each other at the start and end of the bout.",
    answer: true,
  },
  {
    id: 11,
    question: "The coach may change the team fighting order during a round.",
    answer: true,
  },
  {
    id: 12,
    question:
      "If a contestant is injured in an individual match the coach can enter a replacement if he notifies the Organising Commission first.",
    answer: false,
  },
  {
    id: 13,
    question:
      "Each Judge must be equipped with a red and a blue flag or electronic signal device.",
    answer: true,
  },
  {
    id: 14,
    question:
      "If two teams have the same number of victories and points, a deciding bout will be held.",
    answer: true,
  },
  {
    id: 15,
    question:
      "The Match Supervisor (KANSA) will be seated just outside the safety area, will be equipped with only a whistle.",
    answer: true,
  },
  {
    id: 16,
    question:
      "Disqualification by KIKEN means that the contestants are disqualified from that category.",
    answer: true,
  },
  {
    id: 17,
    question: "Heels of more than 4 cm may not be worn with the uniform.",
    answer: true,
  },
  {
    id: 18,
    question:
      "Senior Male bouts are three minutes, and Women, Cadets, and Juniors, two minutes.",
    answer: false,
  },
  {
    id: 19,
    question: "Kumite team members may wear any type of Karate Gi.",
    answer: false,
  },
  {
    id: 20,
    question: "Different type of stripes may be used for team members.",
    answer: false,
  },
  {
    id: 21,
    question:
      " A competitor does not need to change the jacket if ties are torn off during the match.",
    answer: true,
  },
  {
    id: 22,
    question: "Competitors may wear any white Karate Gi.",
    answer: false,
  },
  {
    id: 23,
    question:
      "A fast combination of CHUDAN Geri and TSUKI each of which score in their own right will be given IPPON.",
    answer: false,
  },
  {
    id: 24,
    question: `In Senior matches a light "glove touch" to the throat need no result in a warning or penalty provided there is no actual injury.`,
    answer: false,
  },
  {
    id: 25,
    question:
      "A kick in the groin will not result in a penalty provided the offender did not do it intentionally.",
    answer: false,
  },
  {
    id: 26,
    question:
      "Starting 2023, for Competitors under14 years of age, the WKF approved protective helmet is allowed and compulsory from 1.1.2024.",
    answer: true,
  },
  {
    id: 27,
    question:
      "If SENSHU is withdrawn when it is less than 15 seconds left of the bout, no further SENSHU can be awarded to either competitor.",
    answer: true,
  },
  {
    id: 28,
    question:
      " Should a competitor that has been awarded SENSHU receives a Category 2 warning for avoiding combat when there is less than 15 seconds left of the bout - the competitor will automatically forfeit this advantage.",
    answer: false,
  },
  {
    id: 29,
    question: "Voluntary religiously headwear must be approved by the WKF.",
    answer: true,
  },
  {
    id: 30,
    question:
      "The WKF Competition Supervisor, or the Organizing Commission, can allow Coaches to instead of the track suit top to use any t-shirt.",
    answer: false,
  },
  {
    id: 31,
    question:
      "HANSOKU-CHUI is given when the contestant's potential for winning has been seriously reduced by the opponent's foul.",
    answer: true,
  },
  {
    id: 32,
    question: `An "exchange" is the period preceding when the bout is halted, and the clock stopped.`,
    answer: true,
  },
  {
    id: 33,
    question: "he weight tolerance admitted for male and female 0.5 kg.",
    answer: false,
  },
  {
    id: 34,
    question: "HANSOKU is imposed for serious rules infractions.",
    answer: true,
  },
  {
    id: 35,
    question: "SHIKKAKU can only be imposed after a warning has been given.",
    answer: false,
  },
  {
    id: 36,
    question:
      "If a contestant acts maliciously, SHIKKAKU and not HANSOKU is the correct penalty.",
    answer: true,
  },
  {
    id: 37,
    question:
      "If a contestant acts maliciously, HANSOKU is the correct penalty.",
    answer: false,
  },
  {
    id: 38,
    question:
      "A competitor can be given SHIKKAKU if the behaviour of the coach or non-combatant members of the contestant's delegation is considered to harm the prestige and honour of Karate-do.",
    answer: false,
  },
  {
    id: 39,
    question:
      "The same tolerance applies to both the upper and lower limits of a weight class.",
    answer: true,
  },
  {
    id: 40,
    question:
      "For individual competition, the Round-robin system followed by quarterfinals, semi-finals and final is applied.",
    answer: false,
  },
  {
    id: 41,
    question: "There are five criteria to be met in determining a score.",
    answer: false,
  },
  {
    id: 42,
    question:
      "A male team must present minimum five competitors for the initial round.",
    answer: true,
  },
  {
    id: 43,
    question:
      "Male teams comprise five to seven members with five competing in a round.",
    answer: true,
  },
  {
    id: 44,
    question:
      "A female team must present minimum two competitors for the initial round.",
    answer: false,
  },
  {
    id: 45,
    question:
      "The maximum 32 Competitors per category are divided in 8 groups of 4 Competitors in the Round-robin system.",
    answer: true,
  },
  {
    id: 46,
    question:
      "If the coach continues to interfere after first warning, the Referee will stop the bout, approach the coach again and ask him/her to leave the tatami.",
    answer: true,
  },
  {
    id: 47,
    question:
      "If AKA scores just as AO steps outside of the match area, both the score and warning or penalty can be given.",
    answer: true,
  },
  {
    id: 48,
    question:
      "If a contestant has been physically propelled from the area, Jogai will be given.",
    answer: false,
  },
  {
    id: 49,
    question:
      "In Premier League competition the losers to the finalists in the quarter- and semi-finals compete for the bronze medals.",
    answer: true,
  },
  {
    id: 50,
    question:
      "In cases where there is a tie between two or more Competitors in a group, having the same number of total points, the first criteria to consider is the highest World Ranking at the date of the competition.",
    answer: false,
  },
  {
    id: 51,
    question:
      "It is possible for a Competitor to be disqualified from a bout (HANSOKU) and continue the competition.",
    answer: true,
  },
  {
    id: 52,
    question:
      "A contestant who scores and exits the area before the Referee calls YAME will not receive JOGAI.",
    answer: true,
  },
  {
    id: 53,
    question:
      "Contestants cannot be given penalties after the time-up bell has signalled the end of a bout.",
    answer: false,
  },
  {
    id: 54,
    question:
      "In Junior competition any technique to the face, head or neck, which causes injury will be warned or penalised unless it is the recipient's own fault.",
    answer: true,
  },
  {
    id: 55,
    question: `In Cadet and Junior competition Jodan kicks are allowed to make the lightest touch ("skin touch") provided there is no injury.`,
    answer: true,
  },
  {
    id: 56,
    question:
      "In Senior competition a light touch is allowed for JODAN punches, and a greater tolerance is allowed for JODAN kicks.",
    answer: false,
  },
  {
    id: 57,
    question:
      "An already qualified Competitor cannot be disqualified for misconduct (SHIKKAKU) at the end of the Round-robin.",
    answer: false,
  },
  {
    id: 58,
    question:
      "If a variation of the competition format other than described in these rules is to be applied for a particular tournament, this has to be clearly announced in the tournament bulletin.",
    answer: true,
  },
  {
    id: 59,
    question:
      "A bad behaviour from a coach does not cause a SHIKKAKU to his/her competitor and the competitor does not need to be expelled from the match/bout.",
    answer: true,
  },
  {
    id: 60,
    question:
      " When the Judges see a score, they will immediately signal with joy sticks.",
    answer: true,
  },
  {
    id: 61,
    question:
      "The referee panel is composed of 1 Tatami Manager, 3 Tatami Manager Assistants.",
    answer: false,
  },
  {
    id: 62,
    question:
      "The Referee Panel for each match shall consist of one Referee, four Judges and one Match Supervisor.",
    answer: false,
  },
  {
    id: 63,
    question:
      "If after the bout has started a contestant is found not to be wearing a gum-shield the contestant will be disqualified.",
    answer: false,
  },
  {
    id: 64,
    question: "The Referee will give all commands and make all announcements.",
    answer: true,
  },
  {
    id: 65,
    question:
      "Duration of the Kumite bout is: 1.5 minutes for under14 years and younger.",
    answer: true,
  },
  {
    id: 66,
    question:
      "If three Judges signal a score for AKA, the Referee must stop the bout even if he believes they are mistaken.",
    answer: true,
  },
  {
    id: 67,
    question:
      "If two or more Judges signal a score for the same competitor, the Referee must stop the bout.",
    answer: true,
  },
  {
    id: 68,
    question: `The timing of a bout starts when the Referee gives the signal to start and stops when the Referee calls "YAME" or when the time is up.`,
    answer: true,
  },
  {
    id: 69,
    question:
      "The Referee Panel of a Kumite match shall consist of a Referee, four Judges, a Match Supervisor and a Score Keeper.",
    answer: false,
  },
  {
    id: 70,
    question:
      "When a competitor slips and falls with the torso not touching the Tatami and is immediately scored upon the opponent will be awarded Ippon.",
    answer: false,
  },
  {
    id: 71,
    question:
      "The competitor's coach or its official representative are the only ones allowed to make a protest.",
    answer: true,
  },
  {
    id: 72,
    question:
      "If the Referee does not hear the time-up signal the Match Supervisor will blow the whistle.",
    answer: false,
  },
  {
    id: 73,
    question:
      "The Competitors only must bow properly to each other at the start of the bout.",
    answer: false,
  },
  {
    id: 74,
    question:
      "In individual matches a contestant who withdraws voluntarily from the bout is declared KIKEN and an extra eight points are awarded to the opponent.",
    answer: false,
  },
  {
    id: 75,
    question:
      "Awareness is the state of continued commitment, which endures after the technique has landed.",
    answer: true,
  },
  {
    id: 76,
    question:
      "If the organizer has a check-up of equipment before line-up, it is still KANSA´S responsibility to ensure that the equipment is in accordance with the rules.",
    answer: true,
  },
  {
    id: 77,
    question:
      "A contestant who stays within the match area may score on a contestant outside the match area.",
    answer: true,
  },
  {
    id: 78,
    question: `ATOSHI BARAKU means "15 seconds of bout time remaining".`,
    answer: true,
  },
  {
    id: 79,
    question: `ATOSHI BARAKU means "10 seconds of bout time remaining".`,
    answer: false,
  },
  {
    id: 80,
    question: `"A "skin touch" to the throat is allowed only in Senior competition.`,
    answer: false,
  },
  {
    id: 81,
    question:
      " If there are no points at the end of a bout in team matches the Referee will call for HANTEI.",
    answer: false,
  },
  {
    id: 82,
    question:
      "Excessive contact after repeated failure to block is a consideration for MUBOBI.",
    answer: true,
  },
  {
    id: 83,
    question:
      "A contestant may be penalised for exaggeration even when there is an actual injury.",
    answer: true,
  },
  {
    id: 84,
    question: `The Referee declare the winner; "AO (AKA) NO KACHI", and if necessary, break the tie in case of HANTEI.`,
    answer: true,
  },
  {
    id: 85,
    question:
      " In the event of a tied score at the end of an inconclusive bout the Referee Panel (the Referee and the four Judges) will decide the bout by HANTEI.",
    answer: true,
  },
  {
    id: 86,
    question:
      "When the Referee Panel has made a decision not in accordance with the Rules of Competition, the Match Supervisor (KANSA) will immediately blow his whistle.",
    answer: true,
  },
  {
    id: 87,
    question:
      "A competitor should not be penalized for being winded (loss of breath as a consequence of a technique) or simply reacting to an impact even if the technique merited a point by the opponent.",
    answer: true,
  },
  {
    id: 88,
    question: "In team matches there is no extra bout.",
    answer: false,
  },
  {
    id: 89,
    question:
      "Only the first correctly executed technique of a combination will score.",
    answer: false,
  },
  {
    id: 90,
    question:
      "Competitors that appear at the competition area with unauthorized equipment or irregular Karate-Gi will be given one minute to correct the attire, and the Coach will automatically lose the right to coach that bout.",
    answer: false,
  },
  {
    id: 91,
    question:
      "Competitors that are winded as a result of an impact should be allowed time to catch their breath before the bout is resumed.",
    answer: true,
  },
  {
    id: 92,
    question:
      "JODAN is described as the shoulders and the area above the collar bone.",
    answer: false,
  },
  {
    id: 93,
    question:
      "IPPON is awarded for JODAN kicks or any techniques against an opponent whose any part of the body other than the feet is in contact with the TATAMI.",
    answer: true,
  },
  {
    id: 94,
    question: "HANSOKU CHUI will be given for feigning of injury.",
    answer: false,
  },
  {
    id: 95,
    question:
      "HANSOKU will be given for the first instance of exaggerating an injury.",
    answer: false,
  },
  {
    id: 96,
    question:
      "A loss of breath by the recipient of a blow after receiving a CHUDAN technique does indicate lack of control.",
    answer: false,
  },
  {
    id: 97,
    question:
      "A competitor can be given Hansoku directly for exaggerating the effects of injury.",
    answer: true,
  },
  {
    id: 98,
    question:
      "A technique even if effective, delivered after an order to suspend or halt the bout shall not be scored and may result in a penalty being imposed on the offender.",
    answer: true,
  },
  {
    id: 99,
    question: ` In Cadet Kumite JODAN kicks are allowed to make a "skin touch" provided there is no injury.`,
    answer: true,
  },
  {
    id: 100,
    question:
      "Before the start of a match or bout the Tatami Manager should examine the competitor's medical card.",
    answer: true,
  },
  {
    id: 101,
    question:
      "If there is an error in charting and the wrong contestants compete this cannot be changed afterwards.",
    answer: false,
  },
  {
    id: 102,
    question:
      "An effective technique delivered at the same time as the end of the bout is signalled by the buzzer is valid.",
    answer: true,
  },
  {
    id: 103,
    question:
      "If a contestant is injured and it is considered to be their own fault (MUBOBI), the Referee will decline to give a warning or penalty to the opponent.",
    answer: true,
  },
  {
    id: 104,
    question:
      "KANSA has no vote or authority in matters of judgments such as whether a score was valid or not.",
    answer: true,
  },
  {
    id: 105,
    question: "YUKO is always awarded for punches on the back.",
    answer: false,
  },
  {
    id: 106,
    question: `"Skin touch" is allowed in categories for Competitors 16 years or older.`,
    answer: true,
  },
  {
    id: 107,
    question:
      "A contestant who does not obey the Referee's orders will be given SHIKKAKU.",
    answer: true,
  },
  {
    id: 108,
    question:
      "The Score Supervisor will order the Referee to halt the match when he sees a contravention of the Rules of Competition.",
    answer: false,
  },
  {
    id: 109,
    question:
      "If in a combination, the first technique merits a YUKO and the second merits a penalty, both shall be given.",
    answer: false,
  },
  {
    id: 110,
    question:
      "Skin touch is defined as touching the target without transferring energy into the head or body.",
    answer: true,
  },
  {
    id: 111,
    question: "It is not possible to score while lying on the floor.",
    answer: false,
  },
  {
    id: 112,
    question:
      "A competitor not wearing the WKF approved equipment will be given two minutes to change for the approved type.",
    answer: true,
  },
  {
    id: 113,
    question:
      "A contestant injured in Kumite and withdrawn under the ten-second rule may not compete in the Kata competition.",
    answer: false,
  },
  {
    id: 114,
    question:
      "An injured contestant who has been declared unfit to fight by the Tournament Doctor cannot fight again in that competition.",
    answer: true,
  },
  {
    id: 115,
    question:
      "If a competitor behaves badly on the competition area after the end of the match or bout, the Referee can still give SHIKKAKU.",
    answer: true,
  },
  {
    id: 116,
    question: "The Match Supervisor has a vote in cases of SHIKKAKU.",
    answer: false,
  },
  {
    id: 117,
    question:
      "In Male team matches if a team wins three bouts, then the match is over at that point.",
    answer: true,
  },
  {
    id: 118,
    question:
      "In Female team matches if a team wins two bouts, then the match is over at that point.",
    answer: true,
  },
  {
    id: 119,
    question: `When a contestant seizes the opponent and does not perform an immediate technique or throw the Referee will call "YAME".`,
    answer: true,
  },
  {
    id: 120,
    question:
      "When a Judge is not sure that a technique actually reached a scoring area s/he may signal for the score.",
    answer: true,
  },
  {
    id: 121,
    question:
      "A contestant who commits an act which harms the prestige and honour of Karate-Do will be given HANSOKU.",
    answer: false,
  },
  {
    id: 122,
    question:
      "When an action of a contestant is considered to be dangerous and deliberately violates the rules concerning prohibited behaviour, the contestant will be given Shikkaku.",
    answer: true,
  },
  {
    id: 123,
    question:
      "Correctly executed techniques executed after the time-up signal are valid.",
    answer: false,
  },
  {
    id: 124,
    question: `Correctly executed techniques executed upon or after "WAKARETE" are valid.`,
    answer: false,
  },
  {
    id: 125,
    question:
      "A point may be signaled even if the Judge cannot see the actual point of impact.",
    answer: true,
  },
  {
    id: 126,
    question:
      "At HANTEI if three Judges signal victory for AKA and the fourth Judge signals victory for AO, the Referee must give victory for AKA.",
    answer: true,
  },
  {
    id: 127,
    question:
      "Passivity cannot be given to someone having a lead by point or SENSHU.",
    answer: true,
  },
  {
    id: 128,
    question:
      "Only the Coach designated for the specific bout is allowed to coach and guide the competitor from the place allocated to the coach close to the competition area.",
    answer: true,
  },
  {
    id: 129,
    question: `If a contestant does not regain his or her feet within ten seconds, the Referee will announce "KIKEN" and "KACHI" to the opponent.`,
    answer: false,
  },
  {
    id: 130,
    question: `If a competitor is thrown and lands partly outside the match area, the Referee will immediately call "YAME".`,
    answer: false,
  },
  {
    id: 131,
    question:
      "The Competitor may not signal to the Coach the wish for him/her to request a video review.",
    answer: false,
  },
  {
    id: 132,
    question: "The contestants should be examined outside the tatami.",
    answer: true,
  },
  {
    id: 133,
    question:
      " KANSA has to blow the whistle if the Judges are holding the joy sticks in the wrong hands.",
    answer: true,
  },
  {
    id: 134,
    question:
      "Gum-shields (mouth guards) are obligatory for all Kumite contestants.",
    answer: true,
  },
  {
    id: 135,
    question:
      "After a throw the Referee will allow a maximum of two seconds for a score to be made.",
    answer: false,
  },
  {
    id: 136,
    question:
      "If a competitor scores with a strong side kick and propels the opponent out of the area, the Referee should award WAZA-ARI and give the opponent a warning or penalty for Jogai.",
    answer: false,
  },
  {
    id: 137,
    question:
      "When an official protest is lodged the ensuing matches must be delayed until the result of the protest is given.",
    answer: false,
  },
  {
    id: 138,
    question: "Each Judge will be seated in the corners of the TATAMI.",
    answer: true,
  },
  {
    id: 139,
    question:
      " When the Referee awards a point for a technique which has caused injury the Match Supervisor should signal for the match to be stopped.",
    answer: false,
  },
  {
    id: 140,
    question: `When the Referee fails to hear the "time-up" bell the Scorekeeper should blow his whistle.`,
    answer: false,
  },
  {
    id: 141,
    question:
      "WAKARETE for breaking up a clinch can be given at any time of the bout.",
    answer: true,
  },
  {
    id: 142,
    question:
      "When the Referee wants to give SHIKKAKU she/he may call the Judges for a brief consultation.",
    answer: true,
  },
  {
    id: 143,
    question:
      "When a competitor is injured during a bout in progress and needs medical treatment three minutes will be given in which to receive it, after that the Referee will decide whether the competitor will be declared unfit to fight or whether more time will be given.",
    answer: true,
  },
  {
    id: 144,
    question:
      "A contestant who exits the area (JOGAI) with less than 15 seconds of bout time remaining will be given a minimum of CHUI.",
    answer: false,
  },
  {
    id: 145,
    question:
      "To correct a score given to the wrong contestant the Referee should turn towards the contestant given the score in error, make the sign of TORIMASEN, and then give the score to the opponent.",
    answer: true,
  },
  {
    id: 146,
    question:
      "If a competitor scores with a well-controlled CHUDAN Geri, then accidentally punches his opponent in the face causing a slight injury, WAZA-ARI and a warning should be given.",
    answer: false,
  },
  {
    id: 147,
    question:
      "When an injured contestant has been given medical treatment and the Official Doctor says that the contestant can continue fighting the Referee cannot over-rule the Doctor's decision.",
    answer: false,
  },
  {
    id: 148,
    question:
      "Once WAKARETE is called by the Referee the Coaches do not have the opportunity to make a video request.",
    answer: true,
  },
  {
    id: 149,
    question: `"Avoiding Combat" refers to a situation where a contestant attempts to prevent the opponent from having the opportunity to score by using time wasting behaviour.`,
    answer: true,
  },
  {
    id: 150,
    question:
      "TSUZUKTETE, unless preceded by WAKARETE, is not used if there is less than 15 seconds left of the bout.",
    answer: true,
  },
  {
    id: 151,
    question:
      "In team matches if two contestants injure each other and cannot continue and the points score is equal the Referee will announce HIKIWAKE.",
    answer: false,
  },
  {
    id: 152,
    question:
      "When there are less than 15 seconds of bout time remaining and a losing competitor, desperately trying to equalise, exits the area (JOGAI) they will be given a minimum of HANSOKU-CHUI.",
    answer: true,
  },
  {
    id: 153,
    question: "Techniques which land below the belt cannot score.",
    answer: false,
  },
  {
    id: 154,
    question: "Techniques, which land on the shoulder blade, can score.",
    answer: true,
  },
  {
    id: 155,
    question:
      "If AKA accidentally kicks AO on the hip and AO cannot continue the bout, then AO will be given KIKEN.",
    answer: false,
  },
  {
    id: 156,
    question:
      "If a contestant is obviously out of breath due to lack of endurance, he Referee should stop the match to give him time to recover.",
    answer: false,
  },
  {
    id: 157,
    question:
      "A contestant who establishes a clear lead of eight points is declared the winner.",
    answer: true,
  },
  {
    id: 158,
    question:
      "When time is up; the contestant who has scored the most points is declared the winner.",
    answer: true,
  },
  {
    id: 159,
    question:
      "An otherwise correctly executed technique will be penalized if executed at the same time as WAKARETE has been called.",
    answer: false,
  },
  {
    id: 160,
    question: "There are two degrees of official warnings.",
    answer: true,
  },
  {
    id: 161,
    question: "CHUI is given, up to three times, for smaller infractions.",
    answer: true,
  },
  {
    id: 162,
    question:
      "HANSOKU CHUI cannot be given if three CHUI has not already been given.",
    answer: false,
  },
  {
    id: 163,
    question:
      "A warning or penalty for MUBOBI is only given when a competitor is hit or injured through his own fault or negligence.",
    answer: false,
  },
  {
    id: 164,
    question:
      "A competitor who is hit through their own fault and exaggerates the effect should receive a warning or penalty for MUBOBI or exaggeration but not both.",
    answer: false,
  },
  {
    id: 165,
    question:
      "If a competitor makes a good CHUDAN kick and the opponent catches the leg a score cannot be given.",
    answer: false,
  },
  {
    id: 166,
    question:
      "A competitor makes a JODAN kick with all six scoring criteria. The opponent puts his hand up to intercept the kick and the hand then lightly hits his own face; the Referee can give IPPON since the kick was not effectively blocked.",
    answer: false,
  },
  {
    id: 167,
    question: "A male team can compete with only two competitors.",
    answer: false,
  },
  {
    id: 168,
    question:
      "Authorised advertising for WKF is displayed on the left sleeve of the Karate-Gi.",
    answer: true,
  },
  {
    id: 169,
    question:
      "National Federations are not allowed to put advertising on the competitor's Karate-Gi.",
    answer: false,
  },
  {
    id: 170,
    question:
      "A Kumite competitor who receives KIKEN cannot compete again in that tournament.",
    answer: false,
  },
  {
    id: 171,
    question: "An extra bout is used only for team matches.",
    answer: true,
  },
  {
    id: 172,
    question:
      "KANSA needs to blow the whistle if the Referee gives a score to a competitor and MUBOBI to the other.",
    answer: true,
  },
  {
    id: 173,
    question:
      "Serious violation of conduct, discipline, or malicious behavior merits a HANSOKU CHUI.",
    answer: false,
  },
  {
    id: 174,
    question:
      "In international competitions the Referee must not have the same nationality as either of the competitors although one of the Judges can if both coaches agree.",
    answer: false,
  },
  {
    id: 175,
    question:
      "The Match Supervisor (KANSA) will line up together with the Referee and the Judges.",
    answer: false,
  },
  {
    id: 176,
    question:
      "The coaches will be seated outside the safety area, on their respective sides facing towards the official table.",
    answer: true,
  },
  {
    id: 177,
    question:
      "KANSA does not need to interfere if the Referee gives a score to a competitor and exaggeration to the other.",
    answer: false,
  },
  {
    id: 178,
    question:
      "In team matches the Panel will rotate for each bout - provided that all hold the required license.",
    answer: false,
  },
  {
    id: 179,
    question:
      "In team matches the Panel will rotate for each bout only in bouts for medals.",
    answer: false,
  },
  {
    id: 180,
    question:
      "The Referee can move about the entire tatami including the safety zones.",
    answer: true,
  },
  {
    id: 181,
    question: "Female competitors must have chest protector.",
    answer: false,
  },
  {
    id: 182,
    question:
      "Female competitors don't have to have chest protector if they have body protector.",
    answer: true,
  },
  {
    id: 183,
    question:
      "The red and blue belts must be without any personal embroideries or markings.",
    answer: true,
  },
  {
    id: 184,
    question:
      " When a situation appears to possibly warrant a disqualification the Referee can call one or more of judges for a brief consultation (FUKUSHIN SHUGO) before announcing any decision.",
    answer: true,
  },
  {
    id: 185,
    question:
      "Contestants must wear a white Karate-Gi without personal embroidery.",
    answer: true,
  },
  {
    id: 186,
    question:
      "Personal embroidery in the Karate-Gi is allowed only in bouts for medals.",
    answer: false,
  },
  {
    id: 187,
    question:
      "Where contact is considered by the Referee to be too strong, but does not diminish the Competitor's chances of winning, a warning (CHUI) can be given.",
    answer: true,
  },
  {
    id: 188,
    question:
      "Any technique, which results in injury, unless caused by the recipient cause a warning or penalty.",
    answer: true,
  },
  {
    id: 189,
    question: "An obvious overreaction to a contact will receive a CHUI.",
    answer: false,
  },
  {
    id: 190,
    question:
      "Judges cannot indicate a score or warning before the Referee stops the bout.",
    answer: false,
  },
  {
    id: 191,
    question: "An obvious display of exaggeration will receive a HANSOKU.",
    answer: false,
  },
  {
    id: 192,
    question:
      "Any instance of feigning an injury, whoever slight, will receive a minimum warning of CHUI.",
    answer: true,
  },
  {
    id: 193,
    question:
      "An obvious display of any feigning such as staggering around, falling on the floor, standing up and falling down again, and so on, will receive SHIKKAKU directly.",
    answer: true,
  },
  {
    id: 194,
    question:
      " Any feigning of an injury from a technique that in fact have been determined by the judges as a point will as a minimum result in HANSOKU CHUI.",
    answer: true,
  },
  {
    id: 195,
    question:
      "If a contestant scores with more than one consecutive technique before YAME, the Judges must show the higher point regardless of which sequence the techniques scored.",
    answer: true,
  },
  {
    id: 196,
    question:
      "In team matches, if after the extra bout there are no scores, or it is equal scores with no SENSHU, the match will be decided by HANTEI.",
    answer: true,
  },
  {
    id: 197,
    question:
      "JOGAI occurs when a contestant exits from the competition area, and it is not caused by the opponent.",
    answer: true,
  },
  {
    id: 198,
    question:
      "The minimum warning for running away, avoiding combat or/and wasting time during ATOSHI BARAKU is HANSOKU CHUI.",
    answer: true,
  },
  {
    id: 199,
    question:
      " The pivotal point of the throw must not be above the thrower's hip level and the opponent must be held onto throughout, so that a safe landing can be made.",
    answer: true,
  },
  {
    id: 200,
    question:
      "Holding on to the opponent's Karate Gi is not permitted to break a fall.",
    answer: false,
  },
  {
    id: 201,
    question:
      "KANSA does not need to interfere if the Referee gives a score for a technique done after the time is up.",
    answer: false,
  },
  {
    id: 202,
    question: "YUKO is worth one point.",
    answer: true,
  },
  {
    id: 203,
    question: "WAZA-ARI is worth two points.",
    answer: true,
  },
  {
    id: 204,
    question: "IPPON is worth three points.",
    answer: true,
  },
  {
    id: 205,
    question:
      "It is the duty of the Match Supervisor to ensure before each match or bout that the competitors are wearing the approved equipment.",
    answer: true,
  },
  {
    id: 206,
    question:
      "It is the duty of the Tatami Manager to ensure before each match or bout that the competitors are wearing the approved equipment.",
    answer: false,
  },
  {
    id: 207,
    question:
      "Coaches must present their accreditation together with that of their competitor or team to the official table.",
    answer: false,
  },
  {
    id: 208,
    question: "WAZA-ARI is awarded for CHUDAN Kicks.",
    answer: true,
  },
  {
    id: 209,
    question:
      "YUKO is awarded for any TSUKI or UCHI delivered to any of the seven scoring areas against a competitor that is standing up or off his/her feet and the torso is not on the mat.",
    answer: true,
  },
  {
    id: 210,
    question:
      "IPPON is awarded for JODAN GERI and scoring techniques which are delivered on an opponent who has been thrown, has fallen of their own accord, or is otherwise off their feet.",
    answer: true,
  },
  {
    id: 211,
    question: "Individual bouts cannot be declared a tie.",
    answer: false,
  },
  {
    id: 212,
    question: "Passivity can be given to any Competitor at any time.",
    answer: false,
  },
  {
    id: 213,
    question:
      "Passivity cannot be given during the first 15 seconds of a bout.",
    answer: true,
  },
  {
    id: 214,
    question: "Simulated attacks with the head, knees, or elbows are offenses.",
    answer: true,
  },
  {
    id: 215,
    question:
      "Avoiding combat during the last 15 seconds of the bout (ATO SHIBARAKU) will as a minimum result in HANSOKU CHUI and loss of SENSHU",
    answer: true,
  },
  {
    id: 216,
    question:
      "The Head Coach of a delegation can protest about a judgment to the members of the Refereeing Panel.",
    answer: false,
  },
  {
    id: 217,
    question:
      "In a Kumite Tatami two mats are inverted with the red side turned up in a one meter distance from the mat centre to form a boundary between the contestants.",
    answer: true,
  },
  {
    id: 218,
    question: "Jacket ties must be tied.",
    answer: true,
  },
  {
    id: 219,
    question: "At the beginning of a bout Jackets without ties can be used.",
    answer: false,
  },
  {
    id: 220,
    question:
      "In individual competition a contestant may be replaced by another after the drawing has taking place.",
    answer: false,
  },
  {
    id: 221,
    question:
      "At medal bouts male coaches are required to wear a dark suit, shirt and tie.",
    answer: true,
  },
  {
    id: 222,
    question:
      "At medal bouts female coaches may choose to wear a dress, pantsuit or a combination of jacket and skirt in dark colours.",
    answer: true,
  },
  {
    id: 223,
    question: " At medal bouts female coaches cannot wear religious headwear.",
    answer: false,
  },
  {
    id: 224,
    question:
      "A Competitor that refuses following the instructions of the Referee or display a loss of temper will automatically receive HANSOKU.",
    answer: false,
  },
  {
    id: 225,
    question:
      "Competitors are not entitled to a period of time between matches, equal to the standard duration time of the match, for purpose of rest.",
    answer: false,
  },
  {
    id: 226,
    question:
      " Passivity cannot be given after less than the last 15 seconds of the match.",
    answer: true,
  },
  {
    id: 227,
    question:
      "Any excessive celebration, such as falling on one's knees etc., political, or religious expressions, during or immediately after the bout or match, are prohibited and may be subject to a fine equal to the protest fee determined by EC.",
    answer: true,
  },
  {
    id: 228,
    question:
      "If a Competitor receives KIKEN, or SHIKKAKU, in Round-robin competition all previous bouts are voided from the result.",
    answer: false,
  },
  {
    id: 229,
    question:
      "Grabbing the opponent's arm or Karate-GI with one hand is only allowed for attempting an immediately scoring technique or takedown.",
    answer: true,
  },
  {
    id: 230,
    question:
      "Grabbing the opponent with both hands is never allowed during the match.",
    answer: false,
  },
  {
    id: 231,
    question:
      "It is the Tatami Manager's duty to appoint the video review supervisor.",
    answer: true,
  },
  {
    id: 232,
    question:
      "Should the number of IPPON and WAZA ARI be equal, the decision will be by HANTEI.",
    answer: false,
  },
  {
    id: 233,
    question:
      "In any individual bout, with equal score, and no SENSHU by either Competitor, the first criteria of the decision will be made based on the higher number of IPPON scored in the bout.",
    answer: true,
  },
  {
    id: 234,
    question:
      "If one Competitor has a score indicated by more than one Judge and the score is different between the Judges, the higher will be applied.",
    answer: true,
  },
  {
    id: 235,
    question:
      "The Tatami Manager must notify the central table when a Competitor has been stopped from further competition based on the 10-second rule.",
    answer: true,
  },
  {
    id: 236,
    question:
      "The timekeeper is responsible of starting the 3-minute count for a Competitor who is injured during a bout in progress and requires medical treatment.",
    answer: false,
  },
  {
    id: 237,
    question:
      "The Referee will call YAME when a contestant seizes the opponent and does not perform an immediate technique or throw.",
    answer: true,
  },
  {
    id: 238,
    question:
      " The Referee indicates the winner by hand signal and the order (AKA/AO NO KACHI), and by this action also resolve any tie.",
    answer: true,
  },
  {
    id: 239,
    question:
      "When a contestant seizes the opponent, the Referee will give several seconds for the contestant to perform a throw or technique.",
    answer: false,
  },
  {
    id: 240,
    question:
      "The Coach / NF representative will request the official protest from the Tatami Manager.",
    answer: true,
  },
  {
    id: 241,
    question:
      "The Referee can stop the match and give a point without the Judges opinion.",
    answer: false,
  },
  {
    id: 242,
    question:
      " If the referee does not call the doctor in a 10 second rule situation, Kansa must blow the whistle.",
    answer: true,
  },
  {
    id: 243,
    question:
      "The winning team is the one with the most bout victories excluding those won by SENSHU.",
    answer: false,
  },
  {
    id: 244,
    question:
      "The red and blue belts must be no longer than three-quarters thigh length.",
    answer: true,
  },
  {
    id: 245,
    question:
      "Failure of a Coach / NF representative to deliver a protest in a timely manner can lead to its rejection.",
    answer: true,
  },
  {
    id: 246,
    question:
      "The correct penalty for feigning an injury when the judges have determined that the technique in fact was a score is HANSOKU.",
    answer: false,
  },
  {
    id: 247,
    question:
      "The information of officials implicated in the protest is completed by the Tatami Manager.",
    answer: true,
  },
  {
    id: 248,
    question:
      "Disqualification by KIKEN means that the contestants are disqualified from that category, although it does not affect participation in another category.",
    answer: true,
  },
  {
    id: 249,
    question:
      "Competitors are entitled to a rest period of time between matches, equal to the standard duration time of the match. The exception is in the case of change of equipment color, where this time is extended to five minutes.",
    answer: true,
  },
  {
    id: 250,
    question: `In any bout, if after full time the scores are equal, but one contestant has obtained "first unopposed score advantage" (SENSHU), that contestant will be declared the winner.`,
    answer: true,
  },
  {
    id: 251,
    question: ` By "first unopposed score advantage" (SENSHU) is understood that one contestant has achieved the first instance of scoring on the opponent without having the opponent also score before the signal.`,
    answer: true,
  },
  {
    id: 252,
    question: `Where both contestants score before the signal, no "first unopposed score advantage" is awarded and both contestants retain the possibility of SENSHU later in the bout.`,
    answer: true,
  },
  {
    id: 253,
    question:
      " In the event that a competitor falls, is thrown, or knocked down and does not regain his or her feet immediately, the Referee will call the doctor, and at the same time start a count to ten indicating his count showing a finger for each second.",
    answer: false,
  },
  {
    id: 254,
    question: `An audible signal will be given by the timekeeper 15 seconds before the actual end of the bout and the Referee will announce "ATOSHI BARAKU".`,
    answer: true,
  },
  {
    id: 255,
    question:
      "Kansa must remain silent if the referee gives a warning or penalty for passivity during ATOSHI BARAKU.",
    answer: false,
  },
  {
    id: 256,
    question: "In individual categories a bout can be declared a tie.",
    answer: false,
  },
  {
    id: 257,
    question:
      "For all competition system the Coach will be allowed one video review card for the athlete.",
    answer: false,
  },
  {
    id: 258,
    question:
      "For Round-robin in groups of four the Coach will be allowed one VR card for each participant in the round-robin phase.",
    answer: true,
  },
  {
    id: 259,
    question:
      "The Competitor may ask the Coach to request a video review discretely.",
    answer: true,
  },
  {
    id: 260,
    question:
      " The video review can be requested by the Coach in instances where the Judges awarded a lower score than, in the opinion of the Coach, should be for a higher scoring technique.",
    answer: true,
  },
  {
    id: 261,
    question:
      "The Video Review Supervisor may only award points if scoring before or simultaneously with the other Competitor.",
    answer: true,
  },
  {
    id: 262,
    question:
      " The last 6 seconds before the bout was stopped for the request will always be evaluated for Video Review.",
    answer: true,
  },
  {
    id: 263,
    question:
      "If both Coaches requests video review at the same time, the Video Supervisor may only award the point to whoever is deemed to score first.",
    answer: false,
  },
  {
    id: 264,
    question:
      "Simultaneous scoring techniques can be awarded to both Competitors in Video Review.",
    answer: true,
  },
  {
    id: 265,
    question:
      "The Video Review Supervisor may not overrule any decision by the corner judges with the exception of SENSHU.",
    answer: true,
  },
  {
    id: 267,
    question:
      "Tatami manager must supervise that KANSA stops the bout to instruct the Referee regarding a contravention of the Rules of Competition.",
    answer: true,
  },
  {
    id: 268,
    question:
      "Referee indicates fouls observed, and impose warning and penalties as required by the rules.",
    answer: true,
  },
  {
    id: 269,
    question: "Referee breaks the tie in case of HANTEI.",
    answer: true,
  },
  {
    id: 270,
    question:
      "it is always KANSA´S responsibility to ensure that the equipment is in accordance with the rules before each bout.",
    answer: true,
  },
  {
    id: 271,
    question:
      "In the event that the Referee does not hear the time-up bell, KANSA will blow his whistle.",
    answer: false,
  },
  {
    id: 272,
    question:
      "In two judging system, the Corner Judges will assist the Referee by giving signals for JOGAI, excessive contact, and skin touch for categories where this contravenes the rules.",
    answer: true,
  },
  {
    id: 273,
    question:
      "In two judging system, the Referee remain autonomous in applying warnings and penalties.",
    answer: true,
  },
  {
    id: 274,
    question:
      "In two judging system, if the two Judges, or one Judge and the Referee, show different points for the same competitors, the higher will be given.",
    answer: true,
  },
  {
    id: 275,
    question:
      "In two judging system, if the two Judges, or one Judge and the Referee show different warnings for the same competitor, the lower will be given.",
    answer: true,
  },
];

export default questions;
