import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";
import skylightLogo from "../../assets/skylight logo.png";
import { FiLogOut, FiSettings, FiUser, FiUserCheck } from "react-icons/fi";

const NavBar = () => {
  const { logout } = UserAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); 

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login"); // Navigate to the login page after logout
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <nav className="bg-blue-500 p-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div>
          <Link to="/" className="text-white font-bold text-xl">
            <img src={skylightLogo} alt="" className="w-11 h-11" />
          </Link>
        </div>
        <div className="hidden md:block">
          <ul className="flex space-x-4">
            <li>
              <Link to="/" className="text-white">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-white">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className="text-white">
                Contact
              </Link>
            </li>
            <li className="relative">
              <span
                className="text-white cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
              >
                My Academy
              </span>

              {isOpen && (
                <div className="absolute top-full left-0 bg-white p-2 w-32 border-1 rounded border-gray-300 shadow-lg">
                  <ul className="space-y-2">
                    <li>
                      <Link to="/academy" className="text-blue-500 ">
                        Academic
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/academy/tutorial"
                        className="text-blue-500 "
                      >
                        Video Tutorial
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/academy/class-info"
                        className="text-blue-500 "
                      >
                        Class Info
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/academy/exam-center"
                        className="text-blue-500 "
                      >
                        Exam Center
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/academy/application-forms"
                        className="text-blue-500 "
                      >
                        Application
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li>
              <button
                onClick={handleLogout}
                className="text-white flex items-center"
              >
                <FiLogOut className="mr-1" />
                Logout
              </button>
            </li>
            <li>
              <Link to="/settings" className="text-white flex items-center">
                <FiSettings className="mr-1" />
                Settings
              </Link>
            </li>
            <li>
              <Link to="/account" className="text-white flex items-center">
                <FiUser className="mr-1" />
                Account
              </Link>
            </li>
            <li>
              <Link to="/profile" className="text-white flex items-center">
                <FiUserCheck className="mr-1" />
                Profile
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white flex items-center"
          >
            {isOpen ? "Close" : "Menu"}
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className="block md:hidden">
          <ul className="text-white">
            <li>
              <Link to="/" className="block py-2 px-4">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="block py-2 px-4">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className="block py-2 px-4">
                Contact
              </Link>
            </li>
            <li className="relative">
              <span
                className="block py-2 px-4 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
              >
                My Academy
              </span>
              <hr className="stroke-white" />
              <ul className="space-y-2">
                <li>
                  <Link to="/academy" className="block py-2 px-4">
                    Academic
                  </Link>
                </li>
                <li>
                  <Link to="/academy/tutorial" className="block py-2 px-4">
                    Academic Tutorial
                  </Link>
                </li>
                <li>
                  <Link to="/academy/class-info" className="block py-2 px-4">
                    Class Info
                  </Link>
                </li>
                <li>
                  <Link to="/academy/exam-center" className="block py-2 px-4">
                    Exam Center
                  </Link>
                </li>
                <li>
                  <Link
                    to="/academy/application-forms"
                    className="block py-2 px-4"
                  >
                    Application Forms
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                onClick={handleLogout}
                className="block py-2 px-4 text-white w-full text-left"
              >
                <FiLogOut className="mr-1" />
                Logout
              </button>
            </li>
            <li>
              <Link
                to="/settings"
                className="block py-2 px-4 text-white w-full text-left"
              >
                <FiSettings className="mr-1" />
                Settings
              </Link>
            </li>
            <li>
              <Link
                to="/account"
                className="block py-2 px-4 text-white w-full text-left"
              >
                <FiUser className="mr-1" />
                Account
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                className="block py-2 px-4 text-white w-full text-left"
              >
                <FiUserCheck className="mr-1" />
                Profile
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
