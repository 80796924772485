
import React from 'react'

export default function HeroAbout() {
  return (
    <div>
      <div className='bg-black/10'>
        <img src="https://img.freepik.com/free-photo/silhouette-happy-young-girl-field_1301-5833.jpg?t=st=1708746142~exp=1708749742~hmac=0e1f237b0920c0e13b1d9be0f9a12988d30efd486494d14a03018915a6491914&w=740" 
        className='object-cover mix-blend-overlay' alt="/" />
      </div>
    </div>
  )
}
